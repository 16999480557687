import React, { lazy, Suspense } from 'react'
import type { RouteObject } from 'react-router-dom'
import HomePage from '../pages/home';
import Scan from '@/pages/scan';
const SharePage = lazy(() => import("@/pages/share")) // 我的二维码
export const  routes: RouteObject[] = [
  { path: '/web/douyin', element: <HomePage /> },
  { path: '/web/douyin/share', element: <Suspense><SharePage /></Suspense>  },
  { path: '/web/scan', element: <Suspense><Scan/></Suspense>  },
  { path: '/web/scan/:code', element: <Suspense><Scan/></Suspense>  },
]
