import React from 'react';
import './App.css';
import { routes } from './router/index';
import {
  RouterProvider, createBrowserRouter
} from "react-router-dom";
import { ConfigProvider } from '@arco-design/web-react';
function App() {
  const router = createBrowserRouter(routes);
  return (
    <div className="App">
      <ConfigProvider getPopupContainer={(node) => node.parentElement}>
        <RouterProvider router={router} ></RouterProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
