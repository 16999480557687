import React, { useState, useEffect, useCallback } from 'react';
import './index.less';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header'
import { getConfigParams, authCallBack } from '@/server/index';
import {useSearchParams} from "react-router-dom";
const { location } = window;
const url = location.origin

const timestamp = String(parseInt(String(Date.now() / 1000))); // 生成签名用的时间戳
const nonce_str = 'Wm3WZYTPz0wzccnW'; // 生成签名用的随机字符串
const jsb = ''; // 自定义测试用JSB方法名
const params = {}; // 自定义测试用JSB方法参数
const PageHome = () => {
  const [search] = useSearchParams()
  const navigate = useNavigate();
  const [code] = useState(search.get('code'));
  const [sdk, setSdk] = useState(null);
  const [ready, setReady] = useState(false);
  const [configStatus, setConfigStatus] = useState('');
  const [resultMsg, setResultMsg] = useState('');
  const [client_key, setClientKey] = useState('');
  const [log, setLog] = useState('');
  const [error, setError] = useState('');
  const [ticket, setTicket] = useState(null);
  const [tickLog, setTickLog] = useState('');
  const [third_uid, setThird_uid] = useState(null);
  const toLogin = () => {
    onClickAuth()
  }
  useEffect(() => {
    if (ticket) {
      authCallBack({ ticket: ticket, state: '' }).then(res => {
        setThird_uid(res.data.third_uid);
        navigate(`/web/douyin/share?third_uid=${res.data.third_uid}&code=${code}`)
      })
    }
  }, [ticket]);
  // 打开原生授权页面JSB能力示例
  const onClickAuth = () => {
    if (ready) {
      sdk.showOpenAuth({
        params: {
          client_key,
          state: '',
          scopes: {
            "user_info": 0, // 0: 必选；1: 可选，默认不选中； 2: 可选，默认选中
            "video.data": 0,
            "renew_refresh_token": 0,
            "item.comment": 0,
            "h5.share": 0
          },
          response_type: 'code'
        },
        success: ({ ticket }) => {
          setTicket(ticket);
        },
        error: res => setResultMsg(`Auth Error: ${JSON.stringify(res)}`)
      });
    }
  };

  // 可在URL参数中自定义JSB方法名和参数以测试
  const onClickJSB = () => {
    if (ready) {
      sdk[jsb]({
        params,
        success: res => setResultMsg(`JSB ${jsb} Success: ${JSON.stringify(res)}`),
        error: res => setResultMsg(`JSB ${jsb} Error: ${JSON.stringify(res)}`)
      });
    }
  };


  // 使用JSB能力前，必须先通过Config验证签名
  const config = useCallback(async () => {
    const { client_key, signature } = await getConfigParams({ timestamp, nonce_str, url });
    console.log(client_key, signature, timestamp, nonce_str, url, '签名参数')
    setLog(`client_key: ${client_key}, signature: ${signature} timestamp: ${timestamp}, nonce_str: ${nonce_str}, url:${url}`, );
    sdk.config({
      params: {
        client_key,
        signature,
        timestamp,
        nonce_str,
        url: url
      }
    })
    setClientKey(client_key);
  }, [sdk]);

  useEffect(() => {
    setSdk(window.douyin_open);
    if (sdk) {
      setConfigStatus('SDK Loaded');
      console.log('SDK Loaded')
      config();
      sdk.ready(() => {
        setReady(true);
        setConfigStatus('SDK Config Ready');
      });
      sdk.error(res => {
        setConfigStatus(`SDK Config Error: ${JSON.stringify(res)}`);
      });
    }
  }, [sdk, config]);
  return (
    <div className="homePage">
      <div className="nav-logo" />
      <div className="nav-title" />
      <div className="nav-desc">
        <div className="nav-desc-box">
          <div className="label">哈喽～快来实现你的无限创意吧！</div>
        </div>
      </div>
      <div className="footer-btn">
        <div onClick={() => {
          toLogin()
        }} className="submit-btn1">一键授权登录</div>
        <div className="shouquan">如您点击授权,您将同意并授权</div>
        <div className="yonghu"><span onClick={() => {
          window.open("/policy.html", "policy");
        }}>平台隐私政策</span>, <span onClick={() => {
          window.open("/agreement.html", "agreement");
        }}>平台用户服务协议</span></div>
      </div>
      <div>
        <div>
          configStatus: { configStatus }
          resultMsg: { resultMsg }
          log: { log }
          error: { error }
          ticket: { ticket }
          tickLog: { tickLog }
          third_uid: {third_uid}
        </div>
      </div>
    </div>
  );
};

export default PageHome;
