import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from "react-router-dom";
import { getDyclientCode, getQrcodeStatus, getScanCode } from '@/server/scanCode';
import { QRCodeSVG } from 'qrcode.react';
import byte_dance from "@/assets/images/byte_dance.png"
import xiao_hong_shu from "@/assets/images/xiao_hong_shu.png"
import kuai_shou from "@/assets/images/kuai_shou.png"
import shi_pin_hao from "@/assets/images/shi_pin_hao.png"
import reload from "@/assets/images/reload.png"
import { Message, Modal, Spin, Form, Input, Button } from '@arco-design/web-react';
import "./index.less"
const FormItem = Form.Item;

interface IScanCodeInfo {
  // 批量授权链接状态： 1正常 2过期
  status?: number;
  // 平台
  platform?: number;
  // 过期时间
  expire_at?: number;
  // 二维码数据
  qrcode?: string;
  // 二维码token, 用来查询扫码状态
  token?: string;
}
function Scan() {
  const location = useLocation();
  const [scanCodeLoading, setScanCodeLoading] = useState<boolean>(false);
  const [scanCodeInfo, setScanCodeInfo] = useState<IScanCodeInfo>({});
  const [authStatusMsg, setAuthStatusMsg] = useState("");
  const [sendCodeLoading, setSendCodeLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const timerRef = useRef(null)
  const [form] = Form.useForm()
  const getQrcodeInfo = (current) => {
    const actionMap = {
      1: {
        title: "抖音",
        icon: byte_dance
      },
      2: {
        title: "快手",
        icon: kuai_shou
      },
      3: {
        title: "视频号",
        icon: shi_pin_hao
      },
      4: {
        title: "小红书",
        icon: xiao_hong_shu
      },
    };
    return actionMap[current];
  };
  const [isOverdue, setIsOverdue] = useState<boolean>(false);
  // 视频号
  // 1未授权 2已授权 3二维码过期 4授权失败 5已扫码 6已取消
  // 抖音
  // 1: 获取二维码中2: 二维码已就绪 4: 需要验证码5: 二维码已过期 6: 已授权
  const handleGetScanCode = async (code) => {
    setScanCodeLoading(true)
    const res: any = await getScanCode({ code })
    setScanCodeLoading(false)
    if (res.data.code === 0) {
      setScanCodeInfo(res.data.data)
      form.setFieldValue("tag", res.data.data.token)
      setIsOverdue(false)
    }
  }
  const handleGetQrcodeStatus = async () => {
    const res: any = await getQrcodeStatus({
      platform: scanCodeInfo.platform,
      token: scanCodeInfo.token
    })
    if (res.data.code == 0) {
      const { platform } = scanCodeInfo || {};
      const { status, message } = res.data.data;

      switch (platform) {
        case 1:
          if (status === 5) {
            setIsOverdue(true)
          }
          if (status > 2 && status !== 5) {
            setAuthStatusMsg(message);
            if (status === 4) {
              setVisible(true)
            }

          } else {
            setAuthStatusMsg("");
          }
          if (status === 6 || status === 5) {
            clearInterval(timerRef.current); // 清除定时器
            timerRef.current = null;
          }
          break;
        case 3:
          if (status === 3) {
            setIsOverdue(true)
          }
          if (status !== 1 && status !== 3 && status !== 6) {
            setAuthStatusMsg(message);
          } else {
            setAuthStatusMsg("");
          }
          if (status === 2 || status === 3 || status === 4) {
            clearInterval(timerRef.current); // 清除定时器
            timerRef.current = null;
          }
          break;
        default:
          break;
      }

    }
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) {
      handleGetScanCode(code)
    }
    else {
      Message.error("缺少参数")
    }
  }, [location])

  useEffect(() => {
    if (scanCodeInfo && Object.keys(scanCodeInfo).length) {
      if (timerRef.current) {
        return
      }

      timerRef.current = setInterval(() => {
        handleGetQrcodeStatus()
      }, 3000)
    }
    return () => {
      clearInterval(timerRef.current); // 清除定时器
      timerRef.current = null
    }
  }, [scanCodeInfo])

  return (
    <div className='scan-root'>
      <Modal
        title={null}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <div className='code-modal'>
          <Form autoComplete='off' layout="vertical" form={form} onSubmit={async (values) => {
            setSendCodeLoading(true)
            const result = await getDyclientCode(values)
            setSendCodeLoading(false)
            if (result.code === 0) {
              Message.success(result.data.status)
              setVisible(false)
            }
            else {
              Message.error(result.message)
            }
            form.resetFields()
          }}>
            <FormItem hidden field="tag" >
              <Input />
            </FormItem>
            <FormItem label='验证码' field="code" rules={[{ required: true, message: '必填' }, { match: /^\d{6,}$/, message: "验证码格式错误" }]}>
              <Input placeholder='请输入验证码' />
            </FormItem>
            <div className='sub-code'>
              <Button loading={sendCodeLoading} type="primary" onClick={() => form.submit()}>提交</Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Spin loading={scanCodeLoading}>
        <div className="scan-box">
          <h1>{getQrcodeInfo(scanCodeInfo?.platform)?.title}登录</h1>
          <div className='scan-content'>
            <QRCodeSVG value={scanCodeInfo.qrcode} size={265} imageSettings={{ src: getQrcodeInfo(scanCodeInfo?.platform)?.icon, width: 56, height: 56, excavate: false }} />,
            {isOverdue ? <div className="scan-mask">
              <div onClick={() => {
                const queryParams = new URLSearchParams(location.search);
                const code = queryParams.get("code");
                if (code) {
                  handleGetScanCode(code)
                }
                else {
                  Message.error("缺少参数")
                }
              }}>
                <img src={reload} width={40} alt="" />
              </div>
            </div> : <></>}
            {authStatusMsg && !isOverdue ? <div className="scan-mask-success">
              {authStatusMsg}
            </div> : <></>}
          </div>
          <div>请用{getQrcodeInfo(scanCodeInfo?.platform)?.title}扫描二维码登录 “摘星方舟”</div>
        </div>
      </Spin>
    </div>
  )
}

export default Scan