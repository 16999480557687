import axios from 'axios';

export const getScanCode = async (params) => {
    const res = await axios.get('/api/v1/video/auth/batch/auth/qrcode', { params });
    return res;
};

export const getQrcodeStatus = async (params) => {
    const res = await axios.get('/api/v1/video/auth/qrcode/status', { params });
    return res;
};

export const getDyclientCode = async (data) => {
    const res = await axios.post('/api/v1/douyin-login/dyclient/send_code', data);
    return res.data;
};
