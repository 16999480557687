// ⚠️注意：本文件内的逻辑请在服务端实现，本Demo只展示用
import axios from 'axios';
import md5 from 'md5';
// 获取 ClientToken
// 详细说明见 https://developer.open-douyin.com/docs/resource/zh-CN/dop/develop/openapi/account-permission/client-token
const { location } = window;

const getClientToken = async ({ client_key, client_secret }) => {
    const { data } = await axios.post('https://open.douyin.com/oauth/client_token/', {
        client_key,
        client_secret,
        grant_type: 'client_credential',
    }, {
        headers: {
            "Content-Type" : "application/json"
        }
    });
    const { access_token } = data.data || {};
    return access_token;
};

// 获取 Ticket
// 详细说明见 https://developer.open-douyin.com/docs/resource/zh-CN/dop/develop/openapi/tools-ability/jsb-management/get-jsb-ticket/
const getTicket = async ({ nonce_str, timestamp }) => {
    const { data } = await axios.post('https://dev.api.zxaigc.com/api/v1/video/auth/js/signature', {
        nonce_str: nonce_str,
        timestamp: timestamp,
        url: location.origin
    }, {
        headers: {
            "Content-Type" : "application/json"
        }
    });
    const { signature, app_id } = data.data || {};
    return {
        app_id,
        signature,
    }
};
// js 回调
export const authCallBack = async ({ ticket, state }) => {
    const { data } = await axios.post('https://dev.api.zxaigc.com/api/v1/video/auth/js/callback', {
        code: ticket,
        state
    }, {
        headers: {
            "Content-Type" : "application/json"
        }
    });
    return data;
}
// 爆店码查询视频
export const showVideoApi = async ({ code, third_uid }) => {
    const {data} = await axios.post('https://dev.api.zxaigc.com/api/v1/video/task/show/video', {
        "code": code,
        "third_uid": third_uid,
        "platform": 1,
        "position": ""
    }, {
        headers: {
            "Content-Type" : "application/json"
        }
    });
    return data;
}

// 生成h5链接
export const showLinkApi = async (value) => {
    const { data } = await axios.post('https://dev.api.zxaigc.com/api/v1/video/task/show/link', {
        "title": value?.title,
        "topic": value?.topic,
        "media_url": value?.media_url,
        "poi": value?.poi,
        "mini_program": value?.mini_program,
        "pub_position": value?.pub_position === 1 ? 1 : ""
    }, {
        headers: {
            "Content-Type" : "application/json"
        }
    });
    return data;
}

export const storeCode = async (data) => {
    const res = await axios.post('https://dev.api.zxaigc.com/api/v1/video/task/hot/store/code', {
        code: data.code,
        third_uid: data.third_uid,
        platform: 1,
        position: ''
    }, {
        headers: {
            "Content-Type" : "application/json"
        }
    });
    return res;
}

// 计算签名
// 将从服务端获取到的 ticket，随机字串 noncestr，时间戳 timestamp和当前页面url，排序后进行md5加密生成签名
// 详细说明见 https://developer.open-douyin.com/docs/resource/zh-CN/dop/develop/sdk/web-app/js/signature
const calcSignature = ({ ticket, nonce_str, timestamp, url }) => {
    const str = `jsapi_ticket=${ticket}&nonce_str=${nonce_str}&timestamp=${timestamp}&url=${url}`;
    const sig = md5(str);
    return sig;
};

// 先获取 Client Token，然后通过 Token 获取 JS Ticket
// 详细说明见 https://developer.open-douyin.com/docs/resource/zh-CN/dop/develop/sdk/web-app/js/permission
export const getConfigParams = async ({ timestamp, nonce_str, url }) => {
    const client_key = 'awqba2qjk362vhex'; // clientKey在你的网页应用申请通过后得到
    const client_secret = 'd434e362ef22e5a1c142a9d3b649f2ee'; // clientSecret在你的网页应用申请通过后得到
    // const access_token = await getClientToken({ client_key, client_secret });
    const { signature, app_id} = await getTicket({ nonce_str, timestamp });
    // const signature = await calcSignature({ ticket, timestamp, nonce_str, url });
    return { client_key: app_id, signature };
};
